body {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    padding-top: 60px;
    padding-bottom: 30px;
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
}

header {
    position: fixed;
    width: 100%;
    background-color: #f5f5f5;
    padding: 10px 0;
    top: 0;
    z-index: 1;
}

header > * {
    margin-right: 10px;
}

footer {
    position: fixed;
    width: 100%;
    background-color: #f5f5f5;
    bottom: 0;
}

#content {
    width: 80%;
    margin: 0 auto;
    max-width: 790px;
    padding-bottom: 30px;
}

.chapterForm {
    z-index: 0;
}

.text {
    text-align: justify;
}

#emptyText {
    min-height: 25px;
}

.version {
    color: grey;
    font-size: x-small;
    font-weight: normal;
}

.link {
    font-weight: bold;
    text-decoration: underline;
}
  
.toc_link {
    font-weight: bold;
}
  
.link:hover, .toc_link:hover {
    color: grey;
    font-weight: bold;
    cursor: pointer;
}
  
.mailTo {
    color: grey;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.profileImage {
    max-height: 200px;
}

#bookTabs .ant-tabs-nav-list {
    width: 100%
}

#bookTabs .ant-tabs-nav-list > * {
    width: 33%
}

#chapterTabs .ant-tabs-nav {
    position: fixed;
    z-index: 1;
    width: 100%;
    background: white;
    padding-top: 10px;
    margin-top: -10px;
}

.sharingMessage, .openChapterTabMessage {
    margin-top: 60px;
}
  
@media (min-width:650px) {
    .responsiveLoginForm, .responsiveNewsletterForm, .responsiveChapterSelect {
      display: inline-grid;
      width: 50%;
    }
}
